"use strict";

const body = document.querySelector("body");

const menuBurger = document.querySelector(".menu-burger");
const menu = document.querySelector(".menu");

const steps = document.querySelectorAll(".steps__item");
const stepsInfo = document.querySelectorAll(".steps__right-item");

steps.forEach((step) => {
  step.addEventListener("mouseover", (event) => {
    steps.forEach((step) => {
      step.classList.remove("active");
    });

    stepsInfo.forEach((stepInfo) => {
      stepInfo.classList.remove("active");
    });

    let target = event.target;

    target.classList.add("active");

    let hoverStepId = target.id.split("").reverse()[0];

    let toActiveStepInfo = document.querySelector("#step-info-" + hoverStepId);

    toActiveStepInfo.classList.add("active");
  });
});

menuBurger.addEventListener("click", (event) => {
  if (menuBurger.classList.contains("active")) {
    body.style.overflow = "visible";
  } else {
    body.style.overflow = "hidden";
  }

  menuBurger.classList.toggle("active");
  menu.classList.toggle("active");

  
});


const menuItems = menu.querySelectorAll(".menu__item");

menuItems.forEach((menuItem) => {
  menuItem.addEventListener("click", (event) => {
    menuBurger.classList.remove("active");
    menu.classList.remove("active");

    body.style.overflow = "visible";
  });
});

document.addEventListener("scroll", (event) => {
  const headerMain = document.querySelector(".header_main");
  const header = document.querySelector(".header");
  const wrapper = document.querySelector(".wrapper");

  if (!headerMain) {
    if (window.scrollY > 0) {
      header.style.position = "fixed";
      wrapper.style.paddingTop = `${header.offsetHeight}px`;
    } else if (window.scrollY == 0) {
      header.style.position = "";
      wrapper.style.paddingTop = `0px`;
    }
  }
});

document.addEventListener("touchmove", (event) => {
  const headerMain = document.querySelector(".header_main");
  const header = document.querySelector(".header");
  const wrapper = document.querySelector(".wrapper");

  if (!headerMain) {
    if (window.scrollY > 0) {
      header.style.position = "fixed";
      wrapper.style.paddingTop = `${header.offsetHeight}px`;
    } else if (window.scrollY == 0) {
      header.style.position = "";
      wrapper.style.paddingTop = `0px`;
    }
  }
});
